import React, { useState, useEffect, Fragment } from 'react'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import {
  Basic as AltButton,
  Loading,
  PageHeader,
  Primary as PrimaryButton,
  Seo,
  Warning as DeleteButton,
  Container,
  StyledCheckbox,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
import { useStoreContext } from '../context/StoreContext'
// Services & Utils
import { Magento } from '../services/magento'
import {
  getRegionCode,
  setBillingAndShippingAddressOnCart,
} from '../utils/addressHelpers'
import manageAutoShip, { buildAutoShipAddress } from '../utils/autoShipHelpers'
import { findStoreByLocale } from '../utils/storeHelpers'
//Styles
import { Wrapper } from '../styles/common'

const ShippingInfo = ({ location }) => {
  const [selectedAddressId, setSelectedAddressId] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [hasAddresses, setHasAddresses] = useState(false)
  const [updatingAddress, setUpdatingAddress] = useState(false)

  const {
    langAndCountry,
    magentoStoreName,
    prismicData: {
      prismicGeneral,
      prismicMarketAndLanguage,
      prismicShippingInfoPage: {
        add_new_shipping_address,
        address_delete_error_message,
        address_deleted,
        loading_country_data,
        no_address_on_file,
        please_add_one_below,
        shipping_information,
        update_address,
        update_shipping_address,
        use_this_address,
      },
    },
  } = usePrismic()

  const localeArray = langAndCountry.split('-')
  const parsedCountry = localeArray[1]
  const parsedLang = localeArray[0]
  const magentoStoreOfCurrentCountry =
    parsedLang == 'en'
      ? findStoreByLocale(parsedCountry, prismicMarketAndLanguage.markets)
      : { store_code: parsedCountry }

  const { isStoreLoading } = useStoreContext()

  const {
    cartData,
    buildCart,
    updateAutoShipState,
    autoShipId,
    autoShipData,
    handleSetAutoShipState,
  } = useCartContext()

  const { isAuthLoading, magentoUser, updateMagentoUser } = useAuthContext()

  const returnUrl = location?.state?.returnUrl || '/cart'

  useEffect(() => {
    if (magentoUser && cartData) {
      setHasAddresses(magentoUser.addresses?.length > 0)
    }
  }, [magentoUser, cartData])

  const handleUseThisAddress = async () => {
    const address = findAddressFromSelectedAddressId(selectedAddressId)
    // HANDLE SET ADDRESS ON CART
    if (returnUrl === '/cart') {
      setUpdatingAddress(true)
      await setBillingAndShippingAddressOnCart(
        cartData.id,
        address,
        buildCart,
        false,
        prismicGeneral
      )
      setUpdatingAddress(false)
      // HANDLE SET ADDRESS ON AUTO SHIP
    } else {
      if (!autoShipId) {
        // SAVE ADDRESS TO STATE IF CREATING NEW AUTO SHIP
        handleSetAutoShipState({
          autoShipAddress: buildAutoShipAddress(address),
        })
      } else {
        // UPDATE ADDRESS ON EXISTING AUTO SHIP
        const data = {
          autoShipId,
          dateNextProcess: autoShipData.dateNextProcess,
          address: buildAutoShipAddress(address),
          isActive: true,
        }

        await manageAutoShip
          .update(data, updateAutoShipState, prismicGeneral)
          .then(() =>
            toast.success(
              prismicGeneral.msg_subscription_shipping_address_updated
            )
          )
      }
    }
    navigate(returnUrl)
  }

  const handleCheckbox = (e, { value }) => {
    setSelectedAddressId(value)
    setSelectedAddress(findAddressFromSelectedAddressId(value))
  }

  const findAddressFromSelectedAddressId = id =>
    magentoUser.addresses?.find(address => address.id === id)

  const handleAddEditClick = type =>
    navigate('/add-update-shipping', {
      state: {
        type,
        address: type === 'update' ? selectedAddress : null,
        returnUrl,
      },
    })

  const handleDeleteAddress = async id => {
    try {
      await Magento.User.deleteCustomerAddress({ id })
      toast.success(address_deleted[0].text, { icon: '🗑' })
      updateMagentoUser()
    } catch {
      toast.error(address_delete_error_message[0].text)
    }
  }

  const getMagentoStoreOfAddress = addressCountryCode => {
    if (addressCountryCode == parsedCountry && parsedLang != 'en') {
      return { store_code: parsedCountry }
    }
    return findStoreByLocale(
      addressCountryCode,
      prismicMarketAndLanguage.markets
    )
  }

  if (isStoreLoading || isAuthLoading)
    return (
      <Loading
        loading={isStoreLoading || isAuthLoading}
        message={loading_country_data[0].text}
      />
    )

  return (
    <>
      <Seo title={update_shipping_address[0].text} />
      <PageHeader exitRoute={returnUrl}>
        {shipping_information[0].text}
      </PageHeader>
      <Wrapper>
        <Container padding="1em" direction="column">
          {hasAddresses ? (
            magentoUser?.addresses?.map(address => {
              const magentoStoreOfAddress = getMagentoStoreOfAddress(
                address.country_code.toUpperCase()
              )
              const magentoStoreMatch =
                magentoStoreOfCurrentCountry?.store_code ===
                magentoStoreOfAddress?.store_code
              return (
                <Fragment key={address.id}>
                  {!magentoStoreMatch && (
                    <>
                      <span>{`${prismicGeneral.msg_can_not_ship_to_this_address} ${magentoStoreName}`}</span>
                      <span>{prismicGeneral.msg_add_shipping_address}</span>
                    </>
                  )}
                  <Container
                    borderBottom="1px solid #ccc"
                    marginBottom="1em"
                    padding="1em"
                    style={{ opacity: magentoStoreMatch ? 1 : 0.3 }}
                  >
                    {magentoStoreMatch && (
                      <StyledCheckbox
                        radio
                        fitted
                        value={address.id}
                        checked={selectedAddressId === address.id}
                        onChange={handleCheckbox}
                      />
                    )}
                    <Container
                      onClick={() => {
                        setSelectedAddressId(address.id)
                        setSelectedAddress(
                          findAddressFromSelectedAddressId(address.id)
                        )
                      }}
                      direction="column"
                      align="flex-start"
                      padding="0 0 0 1em"
                      style={{ cursor: 'pointer' }}
                    >
                      <span
                        style={{ fontWeight: 'bolder', fontSize: '1.3em' }}
                      >{`${address.firstname} ${address.lastname}`}</span>
                      <span>{`${address.street[0]}`}</span>
                      {address?.street[1] ? (
                        <span>{`${address.street[1]}`}</span>
                      ) : null}
                      <span>{`${address.city}, ${getRegionCode(
                        address.region
                      )} ${address.postcode}`}</span>
                      <span>{address.country_code}</span>
                    </Container>
                    <DeleteButton
                      style={{ width: '40px' }}
                      icon="trash alternate outline"
                      onClick={() => handleDeleteAddress(address.id)}
                    />
                  </Container>
                </Fragment>
              )
            })
          ) : (
            <>
              <h2>{no_address_on_file[0].text}</h2>
              <span>{please_add_one_below[0].text}</span>
            </>
          )}
          <Container
            style={{ maxWidth: '500px' }}
            justify="space-between"
            padding="0"
          >
            <PrimaryButton
              style={{
                width: '47%',
                marginBottom: '10px',
                visibility: hasAddresses ? 'inherit' : 'hidden',
              }}
              loading={updatingAddress}
              disabled={!selectedAddressId}
              onClick={handleUseThisAddress}
            >
              {use_this_address[0].text}
            </PrimaryButton>
            <PrimaryButton
              style={{
                width: '47%',
                marginBottom: '10px',
                visibility: hasAddresses ? 'inherit' : 'hidden',
              }}
              disabled={!selectedAddressId}
              onClick={() => handleAddEditClick('update')}
            >
              {update_address[0].text}
            </PrimaryButton>
          </Container>
          <AltButton onClick={() => handleAddEditClick('add')}>
            {add_new_shipping_address[0].text}
          </AltButton>
        </Container>
      </Wrapper>
    </>
  )
}

export default ShippingInfo
